var roomService = (function () {
    //var serviceUrl = "https://campi.uminho.pt/api/maps/";
    var serviceUrl = "https://campi-dev.uminho-dev.local/api/maps/";

    var roomService = {
        getRoomInfo: function (roomId) {
            var url = serviceUrl + roomId;
            return axios.get(url);
        },
        getRoomSchedule: function (roomId, startDate, numOfDays) {
            var url = serviceUrl + roomId + '/agenda?startdate=' +
                startDate + '&numberdays=' + numOfDays;
            return axios.get(url);
        },
        getRoomDesc: function (roomId) {
            var url = serviceUrl + "spacedesc/" + roomId;
            return axios.get(url);
        },
        searchByTerm: function (campiId, term, pageIndex, pageSize) {
            var url = serviceUrl + "search/";

            if (campiId != null) {
                url += campiId + "/" + term + "/" + pageIndex + "/" + pageSize;
            } else {
                url += term + "/" + pageIndex + "/" + pageSize;
            }
           
            return axios.get(url);
        }
    };
    return roomService;
})();

